<template>
  <v-container>
    <v-dialog v-if="cancelDialog" v-model="cancelDialog" max-width="360">
      <validation-observer
        ref="obs"
        v-slot="{ errors, invalid, validated, passes, validate }"
      >
        <v-card>
          <v-card-title class="headline">{{ $t("cancel task") }}?</v-card-title>
          <v-divider></v-divider>
          <v-card-subtitle class="pa-4">
            {{ $t("cancel-message") }}
          </v-card-subtitle>
          <v-card-text>
            <validation-provider
              vid="comment"
              :name="$t('cancel reason')"
              rules="required"
              v-slot="{ errors, valid, classes }"
            >
              <v-textarea
                name="comment"
                v-model="reason"
                :error-messages="errors"
                :success="valid"
                :label="$t('cancel reason')"
                :class="classes"
                counter="255"
                :clearable="!isMobile"
              ></v-textarea>
            </validation-provider>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="cancelDialog = false" :disabled="loading" text>
              {{ $t("no") }}
            </v-btn>
            <v-btn
              @click="cancelTask"
              :disabled="loading || invalid || !validated"
              text
            >
              {{ $t("yes") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </v-dialog>

    <v-dialog
      v-if="orderStatusDialog"
      v-model="orderStatusDialog"
      max-width="80%"
    >
      <bbcs-order-status
        :order-number="details.extra.order_number"
        @closeOrderStatus="orderStatusDialog = false"
      />
    </v-dialog>

    <v-simple-table class="ma-4" dense>
      <template v-slot:default>
        <thead v-if="loading">
          <tr>
            <th colspan="2">
              <v-progress-linear indeterminate></v-progress-linear>
            </th>
          </tr>
        </thead>
        <tbody v-if="!loading">
          <tr>
            <td>{{ $t("nr.") }}:</td>
            <td>{{ details.id }}</td>
          </tr>
          <tr>
            <td>{{ $t("status") }}:</td>
            <td>{{ details.status }}</td>
          </tr>
          <tr>
            <td>{{ $t("subject") }}:</td>
            <td>{{ details.subject }}</td>
          </tr>
          <tr>
            <td>{{ $t("created") }}:</td>
            <td>{{ formatDateTime(details.created) }}</td>
          </tr>
          <tr>
            <td>{{ $t("updated") }}:</td>
            <td>{{ formatDateTime(details.updated) }}</td>
          </tr>
          <tr v-if="details.canceled !== null">
            <td>{{ $t("canceled") }}:</td>
            <td>{{ formatDateTime(details.canceled) }}</td>
          </tr>
          <tr>
            <td>{{ $t("task type") }}:</td>
            <td>{{ details.worker_name }}</td>
          </tr>
          <tr>
            <td>{{ $t("username") }}:</td>
            <td>{{ details.username }}</td>
          </tr>
          <tr>
            <td>{{ $t("request IP") }}:</td>
            <td>{{ details.request_ip }}</td>
          </tr>
          <tr v-if="details.note">
            <td>{{ $t("note") }}:</td>
            <td>{{ details.note }}</td>
          </tr>
          <tr v-if="details.error_count">
            <td>
              <span :class="{ 'staff--text': $store.getters.isStaff }">
                {{ $t("error") }}:
              </span>
            </td>
            <td>
              <span
                :class="{
                  'error-message': true,
                  'font-weight-thin': true,
                  'staff--text': $store.getters.isStaff,
                }"
                v-html="details.error_message"
              />
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row no-gutters>
      <v-col cols="12" align="right" justify="center">
        <v-spacer></v-spacer>
        <v-btn
          v-if="!canceled && details.can_cancel"
          :disabled="$store.getters.isReadOnly"
          class="mr-2 secondary"
          small
          text
          @click="cancelDialog = true"
        >
          {{ $t("cancel task") }}
        </v-btn>
        <v-btn
          v-if="
            ['workerticketbbcsorder', 'workerticketmetronetorder'].includes(
              details.worker_code
            )
          "
          :disabled="$store.getters.isReadOnly"
          class="mr-2 primary"
          small
          text
          @click="addHardware"
        >
          {{ $t("order hardware") }}
        </v-btn>
        <v-btn
          v-if="
            ['workerticketbbcsorder'].includes(details.worker_code) &&
            details.extra != null &&
            details.extra.order_number != null
          "
          class="mr-2 primary"
          small
          text
          @click="orderStatusDialog = true"
        >
          {{ $t("order status") }}
        </v-btn>
        <v-btn
          v-if="details.extra != null && details.extra.product_id != null"
          class="mr-2 primary"
          :to="{
            name: 'product-details',
            params: { productId: details.extra.product_id },
          }"
          small
          text
        >
          {{ $t("product") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BbcsOrderStatus from "@/components/orders/BbcsOrderStatus";
import formatDateTime from "@/utils/mixins/formatDateTime";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "TaskShortDetails",
  props: {
    taskId: [String, Number],
    task: Object,
  },
  components: {
    BbcsOrderStatus,
  },
  mixins: [formatDateTime, showErrors, isMobile],
  data: () => ({
    cancelDialog: false,
    orderStatusDialog: false,
    reason: null,
    loading: false,
    details: {},
    canceled: false,
  }),
  watch: {
    taskId() {
      this.getDetails();
    },
    task() {
      this.details = this.task;
    },
  },
  methods: {
    getDetails: function () {
      var that = this;
      this.loading = true;
      this.$http
        .get("workers/" + this.taskId)
        .then((response) => {
          that.details = response.data;
          that.canceled = response.data.canceled !== null;
        })
        .catch((err) => {
          if (err.invalid()) {
            that.$store.commit("setSystemError", {
              msg: err.message,
              title: err.title,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    cancelTask: function () {
      this.loading = true;
      var that = this;
      this.$http
        .post("workers/" + this.taskId + "/cancel", {
          comment: this.reason,
        })
        .then((response) => {
          that.canceled = true;
          that.$emit("workerCancled");
          that.getDetails();
          that.cancelDialog = false;
          that.reason = null;
          that.$snotify.success(
            that.$i18n.t("MsgCancelSuccess", {
              id: that.taskId,
            })
          );
        })
        .catch(function (error) {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loading = false;
        });
    },
    addHardware: function () {
      this.$router.push({
        name: "hardware-list",
        params: { reference: "worker:" + this.taskId },
      });
    },
  },
  mounted() {
    if (this.task) {
      this.details = this.task;
    } else this.getDetails();
  },
};
</script>

<style scoped>
.error-message {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
}
</style>

<i18n>
{
  "en": {
   "username": "Username",
   "request IP": "Request IP",
   "status": "Status",
   "created": "Created",
   "updated": "Updated",
   "task type": "Task type",
   "subject": "Subject",
   "error": "Error",
   "canceled": "Canceled",
   "cancel task": "Cancel task",
   "yes": "Yes",
   "no": "No",
   "MsgCancelSuccess": "Task {id} has been successfully canceled. Please note that it may take a few minutes for the order to be completely cancelled!",
   "cancel-message": "Please note that it may take a few minutes for the order to be completely cancelled!",
   "cancel reason": "Cancel reason",
   "order hardware": "Order hardware",
   "order status": "Order status",
   "note": "Note",
   "product": "Product"
  },
  "de": {
    "username": "Benutzername",
    "request IP": "Vorgang ausgelöst von IP",
    "status": "Status",
    "created": "Angelegt",
    "updated": "Aktualisiert",
    "task type": "Vorgangstyp",
    "subject": "Betreff",
    "error": "Fehler",
    "canceled": "Abgebrochen",
    "cancel task": "Vorgang abbrechen",
    "yes": "Ja",
    "no": "Nein",
    "MsgCancelSuccess": "Auftrag {id} wurde erfolgreich abgebrochen. Bitte beachten Sie, daß es einige Minuten dauern kann, bis der Auftrag vollständig abgebrochen ist!",
    "cancel-message": "Bitte beachten Sie, daß es einige Minuten dauern kann, bis der Auftrag vollständig abgebrochen ist!",
    "cancel reason": "Begründung für den Abbruch",
    "order hardware": "Hardware bestellen",
    "order status": "Bestellstatus",
    "note": "Hinweis",
    "product": "Produkt"
  }
}
</i18n>
