import store from "@/store";

export default {
  methods: {
    formatMoney: function (number, currency = 'CHF', currencyDisplay = 'symbol', useGrouping = true, minimumFractionDigits = 2, maximumFractionDigits = 2) {
      return number.toLocaleString(store.state.locale == 'en' ? 'en-GB' : store.state.locale, {
        style: 'currency',
        currency: currency,
        currencyDisplay: currencyDisplay,
        useGrouping: useGrouping,
        minimumFractionDigits: minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits,
      });
    }
  }
  // Usage:
  // import formatMoney from "@/utils/mixins"
  // export default {
  //   mixins: [formatMoney],
};
