<template>
<div>
  <license-order-3cx :propKey="orderKey" :propAction="orderAction" @reload="delayedReload" ></license-order-3cx>
  <v-card class="mt-4">
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>{{ $t("expiring licenses") }}</v-toolbar-title>
      <v-spacer />

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            :disabled="loading"
            v-on="on"
            @click="dialogSettings = !dialogSettings"
            icon
          >
            <v-icon>mdi-email-edit-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("settings") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn :disabled="loading" v-on="on" @click="getItems()" icon>
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("reload") }}</span>
      </v-tooltip>

      <v-dialog v-model="dialogSettings" persistent max-width="720">
        <v-card>
          <v-card-title>
            {{ $t("settings") }}
            <v-spacer/>
            <v-switch :label="$t('stop reminder emails')" v-model="customer.stop_email" color="error" />
          </v-card-title>

          <v-card-text>
            <div class="mb-3">{{ $t("settings text") }}</div>
            <email-list-input
              v-model="customer.email"
              :name="'email'"
              :label="'E-Mail'"
              :max-length="maxLength != null ? maxLength : null"
              multiple
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelSettings()">{{ $t("cancel") }}</v-btn>
            <v-btn text @click="saveSettings()">{{ $t("save") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>

    <v-card-text>

      <v-text-field
        v-model="search"
        append-outer-icon="mdi-magnify"
        clear-icon="mdi-close-circle"
        :disabled="loading"
        :clearable="!isMobile"
        v-bind:label="$t('search')"
        single-line
        hide-details
      />
      <v-data-table
        :headers="license3cxListHeader"
        :items="license3cxList"
        :search="search"
        item-key="id"
        :loading="loading"
        :sort-by="['expiry_date']"
      >
        <template v-slot:item.expiry_date="{ item }">
          <div class="monospace nowrap">{{ formatDate(item.expiry_date) }}</div>
        </template>

        <template v-slot:item.status="{ item }">
          <v-chip
            :color="getColor(item.blocked_actions, item.exclude_from_reminder)"
            dark
          >
            {{
              getItemStatus(item.blocked_actions, item.exclude_from_reminder)
            }}
          </v-chip>
        </template>

        <template v-slot:item.is_perpetual="{ headers, item }">
          <div>{{ item.is_perpetual ? "Perpetual" : "Annual" }}</div>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-menu v-if="!item.blocked_actions">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="
                  orderKey=item.key; orderAction='Upgrade';
                "
              >
                <v-list-item-icon>
                  <v-icon>mdi-database-arrow-up-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>{{ $t("upgrade") }}</v-list-item-content>
              </v-list-item>

              <v-list-item
                @click="
                  orderKey=item.key; orderAction='RenewAnnual';
                "
              >
                <v-list-item-icon>
                  <v-icon>mdi-update</v-icon>
                </v-list-item-icon>
                <v-list-item-content>{{ $t("renew")}}</v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item
                @click="setReminder(item.id)"
                v-if="item.exclude_from_reminder == true"
              >
                <v-list-item-icon>
                  <v-icon>mdi-email-multiple-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>{{
                  $t("restart reminder")
                }}</v-list-item-content>
              </v-list-item>

              <v-list-item
                @click="deleteReminder(item.id)"
                v-else
              >
                <v-list-item-icon>
                  <v-icon>mdi-email-off-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>{{
                  $t("stop reminder")
                }}</v-list-item-content>
              </v-list-item>


            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</div>
</template>

<style scoped>
.min-sizing {
  min-width: 32px;
  min-height: 32px;
}

.editable {
  display: flex;
  align-items: center;
}
</style>

<script>
import LicenseOrder3cx from "@/views/orders/3cxLicenseOrder";
import formatDate from "@/utils/mixins/formatDate";
import EmailListInput from "@/components/basics/EmailListInput";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "License3cx",
  mixins: [formatDate, isMobile],
  components: {
    LicenseOrder3cx,
    EmailListInput,
  },
  props: {
    maxLength: {
      validator: (prop) =>
        typeof prop === "string" || typeof prop === "number" || prop === null,
      required: false,
      default: null,
    },
  },
  data: function () {
    return {
      loading: false,
      itemsPerPage: 10,
      itemsTotal: 0,
      dialogSettings: false,
      license3cxList: [],
      customer: {},
      orderKey: null,
      orderAction: null,
      search: "",
    };
  },
  computed: {
    license3cxListHeader: function () {
      return [
        { text: this.$i18n.t("license_key"), value: "key" },
        { text: this.$i18n.t("expiry_date"), value: "expiry_date" },
        { text: this.$i18n.t("edition"), value: "edition" },
        { text: this.$i18n.t("is_perpetual"), value: "is_perpetual" },
        { text: "SC", value: "simultaneous_calls" },
        { text: this.$i18n.t("key_type"), value: "key_type" },
        { text: this.$i18n.t("description"), value: "description" },
        { text: "Status", value: "status" },
        { text: this.$i18n.t("actions"), value: "actions", sortable: false },
      ];
    },
  },
  methods: {
    getItems() {
      var that = this;
      that.loading = true;
      this.$http
        .get("services/3cx/expiring_license")
        .then((response) => {
          that.license3cxList = response.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(function () {
          that.loading = false;
        });
    },
    getItem(id) {
      var that = this;
      that.loading = true;
      this.$http
        .get("services/3cx/expiring_license/" + id)
        .then((response) => {
          that.license3cxList.forEach((element) => {
            if (element.id == response.data.id) {
              Object.assign(element, response.data);
            }
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(function () {
          that.loading = false;
        });
    },
    getCustomer() {
      var that = this;
      this.$http
        .get("services/3cx/customer/" + that.$store.state.session.number)
        .then((response) => {
          that.customer = response.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(function () {
          that.loading = false;
        });
    },
    getColor(bocked_actions, exclude_from_reminder) {
      if (bocked_actions) return "workerRunning";
      else if (exclude_from_reminder) return "grey";
      else return "green";
    },
    getItemStatus(bocked_actions, exclude_from_reminder) {
      if (bocked_actions) return this.$i18n.t("processing");
      else if (exclude_from_reminder) return this.$i18n.t("stopped");
      else return this.$i18n.t("active");
    },
    saveSettings() {
      var that = this;
      this.$http
        .put("services/3cx/customer/" + that.$store.state.session.number, {
          email: that.customer.email,
          stop_email: that.customer.stop_email,
        })
        .then((response) => {
          that.customer = response.data;
          that.dialogSettings = !that.dialogSettings;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(function () {
          that.loading = false;
        });
    },
    setReminder(license_id) {
      var that = this;
      that.loading = true;
      this.$http
        .put("services/3cx/expiring_license/" + license_id + "/reminder")
        .then((response) => {
          that.license3cxList.forEach((element) => {
            if (element.id == response.data.id) {
              Object.assign(element, response.data);
            }
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(function () {
          that.loading = false;
        });
    },
    deleteReminder(license_id) {
      var that = this;
      that.loading = true;
      this.$http
        .delete("services/3cx/expiring_license/" + license_id + "/reminder")
        .then((response) => {
          that.license3cxList.forEach((element) => {
            if (element.id == response.data.id) {
              Object.assign(element, response.data);
            }
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(function () {
          that.loading = false;
        });
    },
    delayedReload(key) {
      let that = this
      this.license3cxList.forEach((element) => {
        if (element.key == key) {
          element.blocked_actions = true;
        }
      });
      setTimeout(function() {
          that.getItems();
      }, 300000);
    },
    cancelSettings() {
      this.dialogSettings = false;
    },
  },
  mounted: function () {
    this.getItems();
    this.getCustomer();
  },
};
</script>

<i18n>
{
  "en": {
    "active": "Active",
    "inactive": "Inactive",
    "processing": "Processing",
    "stopped": "Stopped",
    "actions": "Actions",
    "reload":"Reload",
    "name": "Name",
    "description": "Description",

    "stop reminder emails": "Stop reminder E-Mails",

    "cancel": "Cancel",
    "save": "Save",

    "license_key": "License Key",
    "expiry_date": "Expirty Date",
    "edition": "Edition",
    "is_perpetual": "License",
    "key_type": "Key Type",
    "restart reminder" : "Restart reminder",
    "stop reminder" : "Discontinue",
    "upgrade" : "Upgrade",
    "renew" : "Renew",
    "renew mainenance" : "Renew maintenance",
    "order new license": "New license",

    "settings text": "Overrides default billing e-mail for reminder notifications",
    "expiring licenses": "Licenses expiring in 90 days or less"
  },
  "de": {
    "active": "Aktiv",
    "inactive": "Inaktiv",
    "processing": "Verabeitung",
    "stopped" : "Angehalten",
    "actions": "Aktionen",
    "reload":"Neu laden",
    "name": "Name",
    "description": "Beschreibung",

    "stop reminder emails": "Erinnerungs E-Mails abstellen",

    "cancel": "Abbrechen",
    "save": "Speichern",

    "license_key": "Lizenz Schlüssel",
    "expiry_date": "Ablauf Datum",
    "edition": "Edition",
    "is_perpetual": "Lizenz",
    "key_type": "Schlüssel Typ",
    "restart reminder" : "Erinnerungen Aktivieren",
    "stop reminder" : "Nicht Verlängern",
    "upgrade" : "Upgrade",
    "renew" : "Verlängern",
    "renew mainenance" : "Maintenance verlängern",
    "order new license": "Neue Lizenz",

    "settings text": "Überschreibt die standard Rechnungs E-Mail Addresse für Erinnerungs E-Mails",
    "expiring licenses": "Lizenzen mit 90 Tagen oder weniger Laufzeit"
  }
}
</i18n>
