<template>
  <validation-provider
    ref="prov"
    :vid="name"
    :name="label != null ? label : $t('email')"
    :rules="
      (maxLength != null && maxLength != '' ? 'max:' + maxLength : '') +
      '|' +
      (required ? 'required' : '')
    "
    v-slot="{ errors, classes }"
    tag="div"
    immediate
  >
    <v-input
      v-model="input"
      :id="name"
      :label="label != null ? label : null"
      :hint="
        hint != null ? hint : multiple ? $t('multiple emails possible') : ''
      "
      :error-messages="errors"
      :disabled="disabled"
      :class="classes"
      :prepend-icon="prependIcon"
      :persistent-hint="hint != null ? true : false"
    >
      <template v-slot:default>
        <v-chip-group column class="ml-3">
          <email-list-input-chip
            v-for="(email, i) in emails"
            :key="i"
            :index="i"
            :emails="emails"
            :maxLength="
              maxLength != null && maxLength != '' ? Number(maxLength) : null
            "
            :separator="separator"
            :selected.sync="selected"
            :disabled="disabled"
            @commit="commitEmail(i, $event)"
            @delete="deleteEmail($event)"
            @unselect="unselectEmail($event)"
          />
        </v-chip-group>
        <v-btn
          v-if="
            !disabled && selected == null && (emails.length == 0 || multiple)
          "
          @click="
            emails.push('');
            selected = emails.length - 1;
          "
          fab
          x-small
          depressed
        >
          <v-icon>{{ appendIcon }}</v-icon>
        </v-btn>
      </template>
    </v-input>
  </validation-provider>
</template>

<script>
import EmailListInputChip from "./EmailListInputChip";

export default {
  name: "EmailListInput",
  components: {
    EmailListInputChip,
  },
  props: {
    value: {
      required: true,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "email",
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    hint: {
      type: String,
      required: false,
      default: null,
    },
    maxLength: {
      validator: (prop) =>
        typeof prop === "string" || typeof prop === "number" || prop === null,
      required: false,
      default: null,
    },
    separator: {
      type: String,
      default: ";",
    },
    array: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    appendIcon: {
      type: String,
      default: "mdi-plus",
    },
  },
  data: () => ({
    emails: [],
    input: "",
    selected: null,
  }),
  computed: {
    currentLength() {
      return this.emails.join(this.separator).length;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.input = value;
          this.emails = this.toInternal(value);
        }
      },
    },
    emails(value) {
      this.input = this.toExternal(value);
      // use "immediate" property in provider instead
      // this.$refs.prov.validate();
    },
    selected(value) {
      if (this.selected == null)
        this.emails = this.emails.filter(function (e) {
          return (e != null) & (e != "");
        });
    },
  },
  methods: {
    toInternal(value) {
      var values = [];
      if (value == null || value == "") values = [];
      else if (this.array) values = value;
      else
        values = value.split(/[;,\s]+/).filter(function (e) {
          return e.trim() != "";
        });
      return values.filter(function (e) {
        return e.trim() != "";
      });
    },
    toExternal(value) {
      return value.join(this.separator);
    },
    commitEmail(i, value) {
      var emails = [...this.emails];
      emails[i] = value;
      this.emails = emails;
      this.selected = null;
      this.$emit("input", this.toExternal(this.emails));
    },
    deleteEmail(i) {
      var emails = [...this.emails];
      emails.splice(i, 1);
      this.emails = emails;
      this.selected = null;
      this.$emit("input", this.toExternal(this.emails));
    },
    unselectEmail(i) {
      this.selected = null;
    },
  },
};
</script>

<i18n>
{
  "en": {
    "email": "Email",
    "multiple emails possible": "multiple emails possible"
  }, 
  "de": {
    "email": "Email",
    "multiple emails possible": "mehrere Emails möglich"
  }
}
</i18n>
