import axios from 'axios';

export default {
  methods: {
    checkWorkers(workerIds, callback, statusAttName, loadingAttrName = "loadingCheckWorkers") {
      var that = this;
      var status = true;
      this[loadingAttrName] = true;
      var requests = workerIds.map(function(workerId) {
        return that.$http.get("workers/" + workerId);
      });
      axios
        .all(requests)
        .then(axios.spread((...responses) => {
          for (let i = 0; i < responses.length; i++) {
            let response = responses[i];
            status = callback(response.data);
            if (!status) break 
          }
        }))
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function() {
          that[loadingAttrName] = false;
          that[statusAttName] = !status;
        });
    }
  }
};
