var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"close-on-click":false,"close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({attrs:{"close":!_vm.disabled,"outlined":""},on:{"click":function($event){_vm.menu = _vm.selected == null ? true : true;
        _vm.$emit('update:selected', _vm.selected == null ? _vm.index : null);},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.menu = _vm.selected == null ? true : true;
        _vm.$emit('update:selected', _vm.selected == null ? _vm.index : null);},"click:close":function($event){return _vm.$emit('delete', _vm.index)}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.emails[_vm.index])+" ")])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('validation-provider',{ref:"prov",attrs:{"id":"email","name":_vm.$t('email'),"rules":'required|email' +
      (_vm.maxLength != null && _vm.maxLength != ''
        ? '|max:' + (_vm.maxLength - _vm.currentLength - _vm.separator.length)
        : '')},scopedSlots:_vm._u([{key:"default",fn:function({ errors, classes, valid }){return [_c('v-card',{attrs:{"width":"350"}},[_c('v-card-text',[_c('v-text-field',{class:classes,attrs:{"name":"email","label":_vm.$t('email'),"placeholder":_vm.$t('new email'),"error-messages":errors,"maxlength":_vm.maxLength != null && _vm.maxLength != ''
              ? _vm.maxLength - _vm.currentLength - _vm.separator.length
              : '',"counter":_vm.maxLength != null && _vm.maxLength != ''
              ? _vm.maxLength - _vm.currentLength - _vm.separator.length
              : '',"type":"email","autocorrect":"off","autocapitalize":"none","clearable":!_vm.isMobile,"flat":"","solo":"","autofocus":""},on:{"click":function($event){$event.stopPropagation();},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;valid && _vm.$emit('commit', _vm.value)}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","text":""},on:{"click":function($event){return _vm.$emit('unselect', _vm.index)}}},[_vm._v(" "+_vm._s(_vm.$t("discard"))+" ")]),_c('v-btn',{attrs:{"color":"primary","disabled":!valid,"text":""},on:{"click":function($event){return _vm.$emit('commit', _vm.value)}}},[_vm._v(" "+_vm._s(_vm.$t("change"))+" ")])],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }