<template>
  <v-card outlined>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>{{ $t("Order") }}</v-toolbar-title>
    </v-toolbar>
    <v-progress-linear :active="loading" indeterminate />

    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1">
          {{ action ? $t(action) : $t("Action") }}
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="step > 2" step="2">
          {{ $t("Options") }}
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="step > 3" step="3">
          {{ $t("Review") }}
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="4" :complete="step == 4">
          {{ $t("Confirmation") }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <!-- --------------------------------- -->
        <!-- Step 1 Action and Key -->
        <!-- --------------------------------- -->
        <v-stepper-content step="1">
          <validation-observer
            ref="obs"
            v-slot="{ errors, invalid, validated, passes, validate }"
          >
            <v-card-text>
              <validation-provider
                vid="action"
                :name="$t('Action')"
                rules="required"
                v-slot="{ errors, valid, classes }"
              >
                <v-select
                  name="action"
                  :error-messages="errors"
                  :success="valid"
                  :class="classes"
                  v-model="action"
                  :items="actions"
                  :label="$t('Action')"
                  :clearable="!isMobile"
                ></v-select>
              </validation-provider>

              <validation-provider
                v-if="!!action && action !== 'NewLicense'"
                vid="key"
                :name="$t('License Key')"
                rules="required|key3cx"
                v-slot="{ errors, valid, classes }"
              >
                <v-text-field
                  :error-messages="errors"
                  :success="valid"
                  :class="classes"
                  :clearable="!isMobile"
                  name="key"
                  v-model="key"
                  :label="$t('License Key')"
                ></v-text-field>
              </validation-provider>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="invalid"
                color="primary"
                @click="
                  step = 2;
                  getOptions();
                "
              >
                {{ $t("Continue") }}
              </v-btn>
            </v-card-actions>
          </validation-observer>
        </v-stepper-content>

        <!-- --------------------------------- -->
        <!-- Step 2 Options -->
        <!-- --------------------------------- -->
        <v-stepper-content step="2">
          <validation-observer
            ref="obs"
            v-slot="{ errors, invalid, validated, passes, validate }"
          >
            <v-card-text>
              <v-row v-if="!loading && $store.getters.isStaff && $store.getters.customerNumber === 10000">
                <v-col cols="2">
                  <v-alert border="bottom" colored-border color="staff">
                  <validation-provider
                    vid="customer_number"
                    :name="$t('Customer Number')"
                    rules="numeric"
                    v-slot="{ errors, valid, classes }"
                  >
                    <v-text-field
                      :error-messages="errors"
                      :success="valid"
                      :class="classes"
                      :clearable="!isMobile"
                      name="customer_number"
                      v-model="customer_number"
                      :label="$t('Customer Number')"
                    ></v-text-field>
                  </validation-provider>
                  </v-alert>
                </v-col>
              </v-row>

              <v-row
                v-if="
                  (action == 'NewLicense' || action == 'Upgrade') && !loading
                "
              >
                <v-col>
                  <validation-provider
                    vid="edition"
                    :name="$t('Edition')"
                    rules="required"
                    v-slot="{ errors, valid, classes }"
                  >
                    <v-select
                      v-model="edition"
                      :items="options"
                      item-text="Edition"
                      item-value="Edition"
                      :label="$t('Edition')"
                      name="edition"
                      :error-messages="errors"
                      :success="valid"
                      :class="classes"
                      :clearable="!isMobile"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col v-if="edition !== 'Startup'">
                  <validation-provider
                    vid="simultaneousCalls"
                    :name="$t('Simultaneous Calls')"
                    rules="required"
                    v-slot="{ errors, valid, classes }"
                  >
                    <v-select
                      v-model="simultaneousCalls"
                      :items="options_filtered"
                      item-text="SimultaneousCalls"
                      item-value="SimultaneousCalls"
                      :label="$t('Simultaneous Calls')"
                      name="simultaneousCalls"
                      :error-messages="errors"
                      :success="valid"
                      :class="classes"
                      :clearable="!isMobile"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col v-else>
                  <validation-provider
                    vid="Extensions"
                    :name="$t('Extensions')"
                    rules="required"
                    v-slot="{ errors, valid, classes }"
                  >
                    <v-select
                      v-model="extensions"
                      :items="options_filtered"
                      item-text="Extensions"
                      item-value="Extensions"
                      :label="$t('Extensions')"
                      name="Extensions"
                      :error-messages="errors"
                      :success="valid"
                      :class="classes"
                      :clearable="!isMobile"
                    ></v-select>
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row v-if="!loading">
                <v-col v-if="action == 'NewLicense'">
                  <validation-provider
                    vid="additional_insurance_years"
                    :name="$t('Additional Years')"
                    rules="required"
                    v-slot="{ errors, valid, classes }"
                  >
                    <v-select
                      v-model="additional_insurance_years"
                      :label="$t('Additional Years')"
                      :items="additional_insurance_years_options"
                      name="additional_insurance_years"
                      :error-messages="errors"
                      :success="valid"
                      :class="classes"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col
                  v-if="
                    (action == 'RenewAnnual') &&
                    !loading
                  "
                >
                  <validation-provider
                    vid="quantity"
                    :name="$t('Years')"
                    rules="required"
                    v-slot="{ errors, valid, classes }"
                  >
                    <v-select
                      v-model="quantity"
                      :items="quantity_options"
                      :label="$t('Years')"
                      name="quantity"
                      :error-messages="errors"
                      :success="valid"
                      :class="classes"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col>
                    <validation-provider
                      vid="addHosting"
                      :name="$t('Add Hosting')"
                      rules="required"
                      v-slot="{ errors, valid, classes }"
                    >
                      <v-checkbox
                        v-model="addHosting"
                        :label="$t('Add Hosting')"
                        type="checkbox"
                        name="addHosting"
                        :error-messages="errors"
                        :class="classes"
                      ></v-checkbox>
                    </validation-provider>
                </v-col>
              </v-row>

              <v-skeleton-loader
                v-if="loading"
                type="list-item, divider, list-item, divider"
              ></v-skeleton-loader>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="reset()">
                {{ $t("Back") }}
              </v-btn>
              <v-btn
                :disabled="invalid"
                color="primary"
                @click="
                  order(true);
                  step = 3;
                "
              >
                {{ $t("Continue") }}
              </v-btn>
            </v-card-actions>
          </validation-observer>
        </v-stepper-content>

        <!-- --------------------------------- -->
        <!-- Step 3 Review -->
        <!-- --------------------------------- -->
        <v-stepper-content step="3">
          <validation-observer
            ref="obs"
            v-slot="{ errors, invalid, validated, passes, validate }"
          >
            <v-card-text>
              <v-simple-table v-if="!loading">
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t("Type") }}
                    </th>
                    <th class="text-left">
                      {{ $t("Product Name") }}
                    </th>
                    <th class="text-left">
                      {{ $t("Quantity") }}
                    </th>
                    <th class="text-left">
                      {{ $t("Product Description") }}
                    </th>
                    <th class="text-left">
                      {{ $t("Price") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="row in preview" :key="row.line">
                    <td>{{ $t(row.type) }}</td>
                    <td>{{ row.product_name }}</td>
                    <td>{{ row.quantity }}</td>
                    <td style="white-space: pre-line">
                      {{ row.product_description }}
                    </td>
                    <td>{{ formatMoney(row.reseller_price) }}</td>
                  </tr>
                </tbody>
              </v-simple-table>

              <validation-provider
                v-if="!loading"
                vid="description"
                :name="$t('Description')"
                rules="required"
                v-slot="{ errors, valid, classes }"
              >
                <v-text-field
                  v-model="description"
                  :label="$t('Description')"
                  name="description"
                  :error-messages="errors"
                  :success="valid"
                  :class="classes"
                  counter="255"
                  :clearable="!isMobile"
                ></v-text-field>
                {{ $t("Description Text") }}
              </validation-provider>

              <v-skeleton-loader
                v-else
                type="table-thead, divider, table-row, table-row"
              ></v-skeleton-loader>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="step = 2">
                {{ $t("Back") }}
              </v-btn>
              <v-btn
                :disabled="invalid"
                color="primary"
                @click="
                  order(false);
                  step = 4;
                "
              >
                {{ $t("Confirm") }}
              </v-btn>
            </v-card-actions>
          </validation-observer>
        </v-stepper-content>

        <!-- --------------------------------- -->
        <!-- Step 4 Review -->
        <!-- --------------------------------- -->
        <v-stepper-content step="4">
          <v-card-text>
            <v-simple-table v-if="!loading">
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t("Type") }}
                  </th>
                  <th class="text-left">
                    {{ $t("Product Name") }}
                  </th>
                  <th class="text-left">
                    {{ $t("Quantity") }}
                  </th>
                  <th class="text-left">
                    {{ $t("Product Description") }}
                  </th>
                  <th class="text-left">
                    {{ $t("Price") }}
                  </th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in confirmation" :key="row.line">
                  <td>{{ $t(row.type) }}</td>
                  <td>{{ row.product_name }}</td>
                  <td>{{ row.quantity }}</td>
                  <td style="white-space: pre-line">
                    {{ row.product_description }}
                  </td>
                  <td>{{ formatMoney(row.reseller_price) }}</td>
                  <td>
                    <template v-for="license in row.license_keys">
                      <v-chip
                        :key="license.license_key"
                        color="primary"
                        class="ma-2"
                      >
                        {{ license.license_key }}
                      </v-chip>
                      <v-chip
                        :key="license.license_key"
                        v-if="!!license.expiry_date"
                        color="primary"
                        class="ma-2"
                      >
                        {{ $t("ExpiryDate") }}:
                        {{ formatDate(license.expiry_date) }}
                      </v-chip>
                      <v-chip
                        :key="license.license_key"
                        v-if="!!license.maintenance_date && row.type == 'Hosting'"
                        color="primary"
                        class="ma-2"
                      >
                        {{ $t("MaintenanceDate") }}:
                        {{ formatDate(license.maintenance_date) }}
                      </v-chip>
                      <v-chip
                        :key="license.license_key"
                        v-if="!!license.hosting_expiry && row.type == 'Hosting'"
                        color="primary"
                        class="ma-2"
                      >
                        {{ $t("HostingExpiry") }}:
                        {{ formatDate(license.hosting_expiry) }}
                      </v-chip>
                    </template>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-skeleton-loader
              v-else
              type="table-thead, divider, table-row, table-row"
            ></v-skeleton-loader>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="reset()">
              {{ $t("Restart") }}
            </v-btn>
          </v-card-actions>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-card>
</template>

<script>
import formatDate from "@/utils/mixins/formatDate";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import checkWorkers from "@/utils/mixins/checkWorkers";
import formatMoney from "@/utils/mixins/formatMoney";
import TaskShortDetails from "@/components/orders/TaskShortDetails";

export default {
  name: "LicenseOrder3cx",
  mixins: [formatDate, showErrors, isMobile, checkWorkers, formatMoney],
  components: {
    TaskShortDetails,
  },
  props: {
    propKey: {
      type: String,
      default: null,
    },
    propAction: {
      type: String,
      default: null,
    },
  },
  data: function () {
    return {
      loading: false,
      step: 1,
      actions: [
        { text: this.$i18n.t("NewLicense"), value: "NewLicense" },
        { text: this.$i18n.t("Upgrade"), value: "Upgrade" },
        { text: this.$i18n.t("RenewAnnual"), value: "RenewAnnual" },
      ],
      action: null,
      key: null,
      options: [],
      simultaneousCalls: null,
      extensions: null,
      edition: null,
      addHosting: false,
      additional_insurance_years_options: [0, 1, 2, 3, 4],
      additional_insurance_years: 0,
      quantity_options: [1, 2, 3, 4, 5],
      quantity: 1,
      preview: null,
      confirmation: null,
      description: null,
      customer_number: null,
    };
  },
  computed: {
    options_filtered() {
      return this.options.filter((option) => {
        if (this.edition !== null) return option.Edition === this.edition;
        else return true;
      });
    },
  },
  watch: {
    step: function (val) {},
    propKey: function (val) {
      this.reset();
      this.key = val;
    },
    propAction: function (val) {
      this.reset();
      this.action = val;
    },
    simultaneousCalls: function (val) {
      if (val > 32) this.addHosting = false;
    },
  },
  methods: {
    reset() {
      this.step = 1;
      this.options = [];
      this.simultaneousCalls = null;
      this.extensions = null;
      this.edition = null;
      this.addHosting = false;
      this.additional_insurance_years = 0;
      this.quantity = 1;
      this.description = null;
      this.preview = null;
      this.confirmation = null;
      this.customer_number = null;
    },
    getOptions() {
      if (
        !this.action ||
        this.action === "RenewAnnual"
      ) {
        return;
      }
      let path = "";
      if (this.action === "NewLicense") {
        path = "services/3cx/v2/order/get_license_new_options";
      } else if (this.action === "Upgrade") {
        path =
          "services/3cx/v2/order/get_license_upgrade_options?key=" + this.key;
      }
      let that = this;
      that.loading = true;
      this.$http
        .get(path)
        .then((response) => {
          that.options = response.data.sort(function (a, b) {
            return a.SimultaneousCalls - b.SimultaneousCalls;
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(function () {
          that.loading = false;
        });
    },
    order(quote) {
      let payload = {
        quote: quote,
        type: this.action,
        description: this.description,
        add_hosting: this.addHosting,
      };

      if (this.action === "NewLicense" || this.action === "Upgrade") {
        payload.simultaneous_calls = this.simultaneousCalls;
        payload.edition = this.edition;
        payload.is_perpetual = false;
        payload.additional_insurance_years = this.additional_insurance_years;
        if (this.edition === "Startup") {
          payload.extensions = this.extensions;
          payload.simultaneous_calls = 4;
        }
      }
      if (this.action === "RenewAnnual") {
        payload.quantity = this.quantity;
      }
      if (this.action !== "NewLicense") {
        payload.key = this.key;
      }
      if (this.customer_number) {
        payload.target_customer_number = this.customer_number;
      }

      let that = this;
      that.loading = true;

      this.$http
        .post("services/3cx/v2/order", payload)
        .then((response) => {
          if (!quote) {
            that.confirmation = response.data.sort(function (a, b) {
              return a.line - b.line;
            });
            that.$emit("reload", that.key);
          } else {
            that.preview = response.data.sort(function (a, b) {
              return a.line - b.line;
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  mounted: function () {},
};
</script>

<i18n>
{
  "en": {
    "Order": "Order",
    "Action": "Action",
    "Options": "Options",
    "Review": "Review",
    "Confirmation": "Confirmation",
    "NewLicense": "New License",
    "Upgrade": "Upgrade",
    "RenewAnnual": "Renew",
    "Maintenance": "Maintenance",
    "License Key": "License Key",
    "Years": "Years",
    "Edition": "Edition",
    "Add Hosting": "Add Hosting",
    "Simultaneous Calls": "Simultaneous Calls",
    "Additional Years": "Additional Years",
    "Type": "Type",
    "Product Name": "Product Name",
    "Quantity": "Quantity",
    "Product Description": "Product Description",
    "Price": "Price",
    "ExpiryDate": "Expiry Date",
    "MaintenanceDate": "Maintenance Date",
    "HostingExpiry": "Hosting Expiry",
    "Extensions": "Extensions",

    "Restart": "Restart",
    "Continue": "Continue",
    "Back": "Back",
    "Confirm": "Confirm",
    "Description": "Description",
    "Description Text": "Note customer name or FQDN for overview of customer licenses."
  },
  "de": {
    "Order": "Bestellung",
    "Action": "Vorgang",
    "Options": "Optionen",
    "Review": "Überprüfen",
    "Confirmation": "Bestätigung",
    "NewLicense": "Neue Lizenz",
    "Upgrade": "Upgrade",
    "RenewAnnual": "Verlängerung",
    "Maintenance": "Maintenance",
    "License Key": "Lizenz Schlüssel",
    "Years": "Jahre",
    "Edition": "Edition",
    "Add Hosting": "Hosting hinzufügen",
    "Simultaneous Calls": "Simultaneous Calls",
    "Additional Years": "Zusätzliche Jahre",
    "Type": "Type",
    "Product Name": "Produktname",
    "Quantity": "Menge",
    "Product Description": "Produktbeschreibung",
    "Price": "Preis",
    "ExpiryDate": "Ablaufdatum",
    "MaintenanceDate": "Maintenance Ablaufdatum",
    "HostingExpiry": "Hosting Abflaufdatum",
    "Extensions": "Extensions",

    "Restart": "Zurücksetzen",
    "Continue": "Weiter",
    "Back": "Zurück",
    "Confirm": "Bestätigen",
    "Description": "Beschreibung",
    "Description Text": "Kundenname oder FQDN notieren, für Übersicht der Kundenlizenzen."
  }
}
</i18n>
